<template>
  <div class="dropdown-wrapper" :class="{'is-selected' : isSelected}">
    <img v-if="isActive || isSelected" class="btn-icon" src="../../../assets/filters-icons/ic_hotel_name_white.svg" alt="">
    <img v-else class="btn-icon" src="../../../assets/filters-icons/ic_hotel_name_black.svg" alt="">
    <dropdown id="hotel-name" :styleObject="styleObject" :class-name="'hotel-name'" ref="hotelNameDropdown">
      <template v-slot:btn>{{ $t('hotelName') }}</template>
      <template v-slot:body>
        <div class="input-container">
          <input class="hotel-name-input" :placeholder="$t('hotelName')" type="text" v-model="hotelName" @keyup.enter="onEnterKeyUp">
          <img v-if="!hotelName" class="input-icon" src="./../../../assets/list-page/ic_hotel_name_filter.svg" alt="">
        </div>
        <ul class="hotel-list">
          <li v-for="hotel in hotels" v-bind:key="hotel.hotelId">
            <p class="hotel-row" @click="onApply" v-html="highlight(hotel.name)"></p>
          </li>
        </ul>
        <div class="dropdown-button-holder">
            <button @click="onRestore" class="btn btn-restore">{{ $t('restore') }}</button>
        </div>
      </template>
    </dropdown>
  </div>
</template>

<script>
import Dropdown from '@/components/Dropdown'

export default {
  components: {
    Dropdown
  },
  props: {
    congress: Object,
    resetAllFilters: Boolean,
    deselectAllFilters: Boolean,
    filteredHotels: Array
  },
  data: () => {
    return {
      isActive: false,
      hotelName: '',
      styleObject: {
        color: '',
        fontSize: ''
      },
      isSelected: false
    }
  },
  watch: {
    resetAllFilters: function (newValue) {
      if (newValue === true) {
        this.onRestore()
      }
    },
    deselectAllFilters: function (newValue) {
      if (newValue === true) {
        this.isSelected = false
      }
    }
  },
  methods: {
    onRestore () {
      this.$refs.hotelNameDropdown.isHidden = true
      this.$emit('filtered', { name: 'hotelName', value: '' })
      this.isActive = false
      this.isSelected = false
      this.styleObject = {
        color: 'black',
        backgroundColor: 'lightgray'
      }
    },
    highlight (name) {
      return name.replace(new RegExp(this.hotelName, 'gi'), match => {
        return '<strong>' + match + '</strong>'
      })
    },
    onApply () {
      const hotelName = event.target.innerText
      this.$refs.hotelNameDropdown.isHidden = true
      this.$emit('filtered', { name: 'hotelName', value: hotelName })
      this.isActive = true
      this.styleObject = {
        color: 'white',
        backgroundColor: 'black'
      }
    },
    onEnterKeyUp () {
      this.$refs.hotelNameDropdown.isHidden = true
      this.$emit('filtered', { name: 'hotelName', value: this.hotelName })
      this.isActive = true
      this.styleObject = {
        color: 'white',
        backgroundColor: 'black'
      }
    }
  },
  mounted () {
    this.$watch(() => {
      return this.$refs.hotelNameDropdown.isHidden
    }, (value) => {
      this.$emit('overlay', { value: !value })
      this.isSelected = !value
    })

    this.$watch(() => {
      return this.isSelected
    }, () => {
      if (!this.isSelected && !this.isActive) {
        this.styleObject = {
          color: 'black',
          backgroundColor: 'lightgray'
        }
      } else {
        this.styleObject = {
          color: 'white',
          backgroundColor: 'black'
        }
      } 
    })

    if (this.$store.getters.filters.hotelName.length) {
      this.isSelected = true
      this.hotelName = this.$store.getters.filters.hotelname
    }
  },
  computed: {
    hotels () {
      let filtered = [...this.filteredHotels]
      if (this.hotelName) {
        filtered = filtered.filter(hotel => {
          return hotel.name.toLowerCase().includes(this.hotelName.toLowerCase())
        })
      }

      return filtered
    }
  }
}
</script>

<style lang="scss" scoped>
  .hotel-list {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 362px;
    //min-height: 150px;
    overflow: scroll;
    margin-bottom: 10px;
  }

  .hotel-row {
    line-height: 36px;
    margin: 0;
    padding-left: 20px;
    cursor: pointer;
    font-weight: 400;
    text-align: left;
    font-size: 16px;
    color: #151515;
  }

  .dropdown-button-holder {
    display: flex;
    padding: 0 10px 10px 20px;
  }

  .hotel-row:hover {
    background-color: #E9E9E9;
  }

  .hotel-name-input {
    line-height: 21px;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 700;
    border: 1px solid $secondary-black;
    outline: none;
    border-radius: 5px;
    width: 100%;
    padding: 8px 10px;
  }

  ::placeholder {
    color: #A8A8A8;
    font-weight: 400;
  }

  :-ms-input-placeholder {
    color: #A8A8A8;
    font-weight: 400;
  }

  ::-ms-input-placeholder {
    color: #A8A8A8;
    font-weight: 400;
  }

  .input-container {
    position: relative;
    margin: 20px;
    .input-icon {
      position: absolute;
      right: 10px;
      top: 11px;
    }
  }

  .is-selected {
    z-index: 3;
  }
</style>
