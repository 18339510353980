<template>
  <div class="dropdown-wrapper clickable" :class="{'is-selected' : isSelected}">
    <img v-if="isActive || isSelected" class="btn-icon" src="../../../assets/filters-icons/ic_star_white.svg" alt="">
    <img v-else class="btn-icon" src="../../../assets/filters-icons/ic_star_black.svg" alt="">
    <dropdown id="stars" :styleObject="styleObject" :class-name="'is-big'" ref="starsDropdown" >
      <template v-slot:btn>{{ $t('stars') }}</template>
      <template v-slot:body>
        <p class="dropdown-title">{{ $t('selectCategory') }}</p>
        <div class="flex-container">
          <span class="checkbox" v-for="item in stars" :key="item.name">
            <input type="checkbox" :id="item.name" v-model="item.value">
            <label :for="item.name"><img v-for="index in item.number" :src="images.star" :key="index" alt=""></label>
          </span>
        </div>
        <div class="dropdown-button-holder">
          <button @click="onRestore" class="btn btn-restore">{{ $t('restore') }}</button>
          <button @click="onApply" class="btn btn-apply">{{ $t('apply') }}</button>
        </div>
      </template>
    </dropdown>
  </div>
</template>

<script>
import Dropdown from '@/components/Dropdown'

export default {
  components: {
    Dropdown
  },

  props: {
    congress: Object,
    resetAllFilters: Boolean,
    deselectAllFilters: Boolean,
  },

  data: () => {
    return {
      isActive: false,
      images: {
        star: require('./../../../assets/ic_hotel_card_star_1.svg')

      },
      styleObject: {
        color: '',
        fontSize: ''
      },
      isSelected: false
    }
  },
  computed: {
    stars() {
      let stars = this.getStarsList()

      if (this.$store.getters.filters.stars.length) {
        let index = 0
        this.$store.getters.filters.stars.forEach((star) => {
          const nameList = ['defaultStars', 'oneStars', 'twoStars', 'threeStars', 'fourStars', 'fiveStars']
          if (star == null){
            return
          }
          index = stars.findIndex(obj => obj.name == nameList[star])
          stars[index].value = true
        })
      }
      return stars
    }
  },
  mounted () {
    this.$watch(() => {
      return this.$refs.starsDropdown.isHidden
    }, (value) => {
      this.$emit('overlay', { value: !value })
      this.isSelected = !value
    })

    this.$watch(() => {
      return this.isSelected
    }, () => {
      if (!this.isSelected && !this.isActive) {
        this.styleObject = {
          color: 'black',
          backgroundColor: 'lightgray'
        }
      } else {
        this.styleObject = {
          color: 'white',
          backgroundColor: 'black'
        }
      } 
    })
    if(this.$store.getters.filters.stars.length > 0) {
      this.isSelected = true
    }
  },
  watch: {
    resetAllFilters: function (newValue) {
      if (newValue === true) {
        this.onRestore()
      }
    },
    deselectAllFilters: function (newValue) {
      if (newValue === true) {
        this.isSelected = false
      }
    },
    stars: function () {
      if (this.$store.getters.filters.stars.length) {
        this.isSelected = true
      }
    }
  },
  methods: {
    getStarsList() {
      const starsArray = this.$store.getters.hotels
        .reduce((acummulator, current) => {
          acummulator.push(current.stars)
          return acummulator
        }, [])
        .sort()

      let stars = [...new Set(starsArray)].map((star) => {
        if (star === 1) return { name: 'oneStars', number: 1, value: false }
        if (star === 2) return { name: 'twoStars', number: 2, value: false }
        if (star === 3) return { name: 'threeStars', number: 3, value: false }
        if (star === 4) return { name: 'fourStars', number: 4, value: false }
        if (star === 5) return { name: 'fiveStars', number: 5, value: false }
        return { name: 'defaultStars', number: 0, value: false }
      })

      return stars
    },
    onApply () {
      this.isSelected = false
      this.$refs.starsDropdown.isHidden = true

      const stars = this.stars.map((star) => {
        return star.value ? star.number : null
      })

      this.$emit('filtered', { name: 'stars', value: stars })
      this.isActive = true
      this.styleObject = {
        color: 'white',
        backgroundColor: 'black'
      }
      this.activeZIndex = 0
    },
    onRestore () {
      this.isSelected = false
      this.stars.forEach((star) => {
        star.value = false
      })

      this.$refs.starsDropdown.isHidden = true
      this.$emit('filtered', { name: 'stars', value: '' })
      this.isActive = false
      this.styleObject = {
        color: 'black',
        backgroundColor: 'lightgray'
      }
      this.activeZIndex = 0
    }
  }
}
</script>

<style lang="scss" scoped>
  .flex-container {
    margin-top: 40px;
    margin-bottom: 70px;
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  label {
    cursor: pointer;
  }

  .checkbox {
    margin-bottom: 6px;
  }

  .dropdown-button-holder {
    display: flex;
    justify-content: space-between;
  }

  .checkbox label {
    position: relative;
    left: 25px;
  }

  .checkbox label::before {
    top: 0;
    left: -25px;
  }

  .checkbox label::after {
    left: -22px;
    top: 3px;
  }

  .is-selected {
    z-index: 3;
  }
</style>
