<template>
  <div class="dropdown-wrapper" :class="{'is-selected' : isSelected}">
    <img v-if="isActive || isSelected" class="btn-icon" src="../../../assets/filters-icons/ic_location_white.svg" alt="">
    <img v-else class="btn-icon" src="../../../assets/filters-icons/ic_location_black.svg" alt="">
    <dropdown id="location" :styleObject="styleObject"  :class-name="'is-big'"  ref="locationDropdown">
      <template  v-slot:btn>{{ $t('location') }}</template>
      <template v-slot:body>
        <p class="dropdown-title">{{ $t('maximumDistance') }}</p>
        <div class="slider">
          <vue-slider
            ref="locationSlider"
            v-model="distanceFrom.distance"
            v-bind="options"
          ></vue-slider>
        </div>
        <p class="initial-distance" style="margin-top: 15px">100m</p>
        <p class="upto-distance">{{ $t('upTo') }} <strong>{{ distanceFrom.distance }}</strong> km</p>
        <div class="distance-from">
          <div class="distance-from__header">
            <input type="radio" id="one" value="congress" v-model="distanceFrom.type">
            <label class="noselect" for="one" :class="{ selected: distanceFrom.type === 'congress' }">{{ $t('congress') }}</label>
            <span>|</span>
            <input type="radio" id="two" value="downtown" v-model="distanceFrom.type">
            <label class="noselect" for="two" :class="{ selected: distanceFrom.type === 'downtown' }">{{ $t('downtown') }}</label>
          </div>
        </div>
        <div class="dropdown-button-holder" style="margin-top: 15px">
          <button @click="onRestore" class="btn-restore">{{ $t('restore') }}</button>
          <button @click="onApply" class="btn btn-apply">{{ $t('apply') }}</button>
        </div>
      </template>
    </dropdown>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import vueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import Dropdown from '@/components/Dropdown'

export default {
  components: {
    Dropdown,
    vueSlider
  },
  props: {
    resetAllFilters: Boolean,
    deselectAllFilters: Boolean,
  },
  data: () => {
    return {
      poiInputSelected: false,
      isActive: false,
      pois: [],
      errorMessage: '',
      selectedPoi: '',
      poiIsSelected: false,
      distanceFrom: {
        type: 'congress',
        lat: 0,
        lng: 0,
        distance: 0
      },
      styleObject: {
        color: '',
        fontSize: ''
      },
      otherChecked: false,
      picked: '',
      hoverCount: 0,
      value: 0,
      options: {
        dotSize: 14,
        width: 'auto',
        height: 4,
        contained: false,
        direction: 'ltr',
        data: null,
        dataLabel: 'label',
        dataValue: 'value',
        min: 0,
        max: 10,
        interval: 0.1,
        disabled: false,
        clickable: true,
        duration: 0.5,
        adsorb: false,
        lazy: false,
        tooltip: 'always',
        tooltipPlacement: 'top',
        tooltipFormatter:'{value} Km',
        useKeyboard: false,
        keydownHook: null,
        dragOnClick: false,
        enableCross: true,
        fixed: false,
        minRange: void 0,
        maxRange: void 0,
        order: true,
        marks: false,
        dotOptions: void 0,
        dotAttrs: void 0,
        process: true,
        dotStyle: {
          backgroundColor: 'black',
          border: '0.5px solid white',
          color: 'white'
        },
        railStyle: void 0,
        processStyle: { backgroundColor: 'black'  },
        tooltipStyle: {
          backgroundColor: 'transparent',
          border: 'none',
          borderColor: 'transparent',
          color: 'black',
          fontWeight: 'light'
        },
        stepStyle: void 0,
        stepActiveStyle: void 0,
        labelStyle: void 0,
        labelActiveStyle: void 0,
      },
      isSelected: false
    }
  },
  mounted () {

    this.$watch(() => {
      return this.$refs.locationDropdown.isHidden
    }, (value) => {
      this.$emit('overlay', { value: !value })
      this.isSelected = !value
    })

    this.$watch(() => {
      return this.isSelected
    }, () => {
      if (!this.isSelected && !this.isActive) {
        this.styleObject = {
          color: 'black',
          backgroundColor: 'lightgray'
        }
      } else {
        this.styleObject = {
          color: 'white',
          backgroundColor: 'black'
        }
      } 
    })

    if (this.$store.getters.filters.distanceInKm && this.$store.getters.filters.distanceFromType) {
      this.isSelected = true
      this.distanceFrom.lat = this.$store.getters.filters.distanceFromLat
      this.distanceFrom.lng = this.$store.getters.filters.distanceFromLng
      this.distanceFrom.distance = this.$store.getters.filters.distanceInKm
      this.distanceFrom.type = this.$store.getters.filters.distanceFromType
    }
  },
  computed: {
    filteredPois () {
      if (this.selectedPoi) {
        let filteredPois = [...this.pois]
        filteredPois = filteredPois.filter(poi => poi.name.toLowerCase().includes(this.selectedPoi.toLowerCase()))
        return filteredPois
      }
      return []
    },
    otherPois () {
      let filteredPois = [...this.pois]
      filteredPois = filteredPois.filter(poi => !poi.name.toLowerCase().includes(this.selectedPoi.toLowerCase()))
      return filteredPois
    },
    ...mapGetters([
      'site',
      'cityCenterLat',
      'cityCenterLng',
      'congressLat',
      'congressLng',
    ])
  },

  watch: {
    resetAllFilters: function (newValue) {
      if (newValue === true) {
        this.onRestore()
      }
    },

    deselectAllFilters: function (newValue) {
      if (newValue === true) {
        this.isSelected = false
      }
    }
  },

  methods: {
    clearPoi () {
      this.poiInputSelected = false
      this.poiIsSelected = false
      this.selectedPoi = ''
      this.errorMessage = ''
    },
    handleInputFocus () {
      this.distanceFrom.type = 'other'
      this.poiInputSelected = true
      this.selectedPoi = ''
      this.poiIsSelected = false
    },
    handleInputBlur () {
      this.poiInputSelected = false
    },
    handlePoiClick (e) {
      // TODO - Review this innerText
      this.selectedPoi = e.target.innerText
      this.poiIsSelected = true
    },
    onApply () {
      if (this.distanceFrom.type === 'other' && !this.poiIsSelected) {
        this.errorMessage = 'Please select a landmark'
      } else {
        this.isSelected = false
        this.$refs.locationDropdown.isHidden = true

        if (this.distanceFrom.distance === 0)
          this.distanceFrom.distance = 0.1


        if (this.distanceFrom.type === 'other') {
          const selectedPoi = this.pois.find(poi => poi.name.toLowerCase().includes(this.selectedPoi.toLowerCase()))
          this.distanceFrom.lat = selectedPoi.lat
          this.distanceFrom.lng = selectedPoi.lng
        }

        this.$emit('filtered', { name: 'distanceFromLat', value: this.distanceFrom.lat })
        this.$emit('filtered', { name: 'distanceFromLng', value: this.distanceFrom.lng })
        this.$emit('filtered', { name: 'distanceInKm', value: this.distanceFrom.distance })
        this.$emit('filtered', { name: 'distanceFromType', value: this.distanceFrom.type })
        this.isActive = true
        this.styleObject = {
          color: 'white',
          backgroundColor: 'black'
        }
      }
    },
    onRestore () {
      this.isSelected = false
      this.value = 1
      this.distanceFrom.type = 'congress'
      this.distanceFrom.lat = 0
      this.distanceFrom.lng = 0
      this.distanceFrom.distance = 0
      this.poiIsSelected = false
      this.poiInputSelected = false
      this.selectedPoi = ''
      this.$emit('filtered', { name: 'distanceFromLat', value: 0 })
      this.$emit('filtered', { name: 'distanceFromLng', value: 0 })
      this.$emit('filtered', { name: 'distanceInKm', value: 0 })
      this.$refs.locationDropdown.isHidden = true
      this.isActive = false
      this.styleObject = {
        color: 'black',
        backgroundColor: 'lightgray'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dropdown-button-holder {
  display: flex;
  justify-content: space-between;
}

.dropdown-title {
  margin-bottom: 50px;
}

.distance-from__header {
  display: flex;
  justify-content: space-around;
}

label {
  color: #a8a8a8;
  font-size: 16px;
  font-weight: initial;
  cursor: pointer;
  position: relative;
  margin-left: 25px;
}

span {
  margin: 0 25px;
  color: #a8a8a8;
}

.initial-distance {
  font-size: 16px;
  margin-bottom: 40px;
}

.selected {
  border-bottom: 2px solid $secondary-black;
}

input[type=text] {
  width: 100%;
  line-height: 38px;
  font-size: 16px;
  padding-left: 15px;
  border: 1px solid #B6B6B6;
  opacity: 0.5;
  color: #a8a8a8;
  border-radius: 5px;
  outline: none;
  margin-bottom: 10px;
}

input[type=text]:focus {
  border: 1px solid $secondary-black;
}

input[type=radio] {
  display: none;
}

label::before {
  content: url("./../../../assets/list-page/ic_hotel_name_center_filter.svg");
  position: absolute;
  left: -25px;
}

.poi-input-container {
  position: relative;
  margin: 20px 0;
  margin-top: 25px;

  img {
    position: absolute;
    right: 10px;
    top: 11px;
  }
}

.poi-input-dropdown {
  position: absolute;
  top: 50px;
  right: 0;
  left: 0;
  border-radius: 5px;
  background: #FFFFFF;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.3);
}

p.dropdown-row {
  margin: 10px 0;
}

.dropdown-row {
  font-weight: initial;
  line-height: 30px;
  padding-left: 20px;

  &:hover {
    background-color: #a8a8a8;
    cursor: pointer;
  }

  p {
    margin: 0;
  }
}

.upto-distance {
  font-weight: 400;
  font-size: 16px;
}

.vue-slider-tooltip {
  opacity: 0 !important;
}

.is-selected {
  z-index: 3;
}

.error-container {
  height: 20px;

  .error-message {
    line-height: 20px;
    color: red;
    font-weight: lighter;
  }
}

</style>
