<template>
  <div class="filters">

    <date-filter class="filter" 
      v-model="range_dates"
      @apply="onApply"
      @restore="onRestore"
      @overlay="toggleOverlay"
      @setDefaultDates="setDates"
    >
    </date-filter>

    <stars-filter
      class="filter"
      @filtered="onFilterSelected"
      @overlay="toggleOverlay"
      :resetAllFilters="resetAllFilters"
      :deselectAllFilters="deselectAllFilters"
    ></stars-filter>

    <location-filter
      class="filter"
      @filtered="onFilterSelected"
      @overlay="toggleOverlay"
      :resetAllFilters="resetAllFilters"
      :deselectAllFilters="deselectAllFilters"
    ></location-filter>

    <price-filter
      class="filter"
      @filtered="onFilterSelected"
      @overlay="toggleOverlay"
      :resetAllFilters="resetAllFilters"
      :deselectAllFilters="deselectAllFilters"
    ></price-filter>

    <hotel-name-filter
      class="filter"
      @filtered="onFilterSelected"
      @overlay="toggleOverlay"
      :filteredHotels="filteredHotels"
      :resetAllFilters="resetAllFilters"
      :deselectAllFilters="deselectAllFilters"
    ></hotel-name-filter>
  </div>
</template>

<script>
import DateFilter from '@/components/list-page/filters/DateFilter'
import HotelNameFilter from "@/components/list-page/filters/HotelNameFilter";
import LocationFilter from "@/components/list-page/filters/LocationFilter";
import PriceFilter from "@/components/list-page/filters/PriceFilter";
import StarsFilter from "@/components/list-page/filters/StarsFilter";
import {mapGetters} from 'vuex'

export default {
  components: {
    DateFilter,
    HotelNameFilter,
    LocationFilter,
    PriceFilter,
    StarsFilter,
  },
  props: {
    filteredHotels: Array,
    resetAllFilters: Boolean,
    deselectAllFilters: Boolean,
  },
  computed: {
    ...mapGetters({site: 'site'})
  },
  data: () => {
    return {
      smallButton: false,
      range_dates: {
        start: null,
        end: null
      }
    };
  },
  methods: {
    onFilterSelected(filter) {
      this.$emit("filtersChange", filter);
    },
    toggleOverlay(e) {
      this.$emit("overlay", { value: e.value });
    },
    setDates() {
      let startDate = localStorage.getItem("currentStartDate") || this.$store.getters.site.start_at
      let endDate = localStorage.getItem("currentEndDate") || this.$store.getters.site.finish_at
      this.range_dates.start = new Date(new Date(startDate).toLocaleString('en-US', {timeZone: "Europe/Madrid"}))
      this.range_dates.end = new Date(new Date(endDate).toLocaleString('en-US', {timeZone: "Europe/Madrid"}))
    },
    onApply () {
      this.$emit("filterDate", { startDate: this.range_dates.start, endDate: this.range_dates.end})
    },
    onRestore () {
      this.setDates()
      this.$emit("restoreDate", { startDate: this.site.start_at, endDate: this.site.finish_at})
    },
  },
  beforeMount() {
    this.setDates()
  },
};
</script>

<style lang="scss" scoped>
.filters {
  margin-top: 60px !important;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: white;
  font-weight: bold;
  font-size: 14px;
  height: 100px;
  box-shadow: 0px 1px 10px gray;
}

@media (max-width: 1440px) {
  .filters {
    justify-content: flex-start;
  }
}

.filters > .filter {
  margin: 7.5px;
}
</style>
