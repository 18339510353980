<template>
  <div class="dropdown-wrapper" :class="{'is-selected' : isSelected}">
    <img v-if="isActive || isSelected" class="btn-icon" src="@/assets/filters-icons/ic_calendar_white.svg" alt="">
    <img v-else class="btn-icon" src="@/assets/filters-icons/ic_calendar_black.svg" alt="">
    <dropdown id="" :styleObject="styleObject" :class-name="'is-big'" ref="calendarDropdown">
      <template v-slot:btn>
        <template v-if="!isSmallButton"> {{ showDates() }}</template>
      </template>
      <template v-slot:body>
        <range-picker v-model="value" :attrs="calendarAttrs"></range-picker>
        <div class="dropdown-button-holder">
          <button @click="onRestore" class="btn btn-restore">{{ $t('restore') }}</button>
          <button @click="onApply" class="btn btn-apply">{{ $t('apply') }}</button>
        </div>
      </template>
    </dropdown>
  </div>
</template>

<script>

import Dropdown from '@/components/Dropdown'
import RangePicker from '@/components/RangePicker'
import {mapGetters} from 'vuex'

export default {
  components: {
    Dropdown,
    RangePicker
  },
  computed: {
    ...mapGetters({site: 'site'}),
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
  },
  data() {
    return {
      calendarAttrs: [
        {
          key: 'today',
          highlight: {
            color: 'gray',
            fillMode: 'outline',
          },
          dates: new Date(),
        },
      ],
      styleObject: {
        color: '',
        fontSize: ''
      },
      isActive: false,
      isSelected: false,
    }
  },
  props: {
    modelValue: Object
  },
  methods: {
    showDates() {
      return `${this.value.start.toLocaleDateString()} ${this.value.end.toLocaleDateString()}`
    },
    onApply () {
      this.$emit("apply");
      this.$refs.calendarDropdown.isHidden = true
      this.isActive = true
    },
    onRestore () {
      this.$emit("restore");
      this.$refs.calendarDropdown.isHidden = true
      this.isActive = false
    }
  },
  mounted () {
    this.$watch(() => {
      return this.$refs.calendarDropdown.isHidden
    }, (value) => {
      this.$emit('overlay', { value: !value })
      this.isSelected = !value
    })

    this.$watch(() => {
      return this.isSelected
    }, () => {
      if (!this.isSelected && !this.isActive) {
        this.styleObject = {
          color: 'black',
          backgroundColor: 'lightgray'
        }
        this.$emit('setDefaultDates')
      } else {
        this.styleObject = {
          color: 'white',
          backgroundColor: 'black'
        }
      } 
    })
    if ((this.$store.getters.site.start_at != localStorage.getItem("currentStartDate")) && (this.$store.getters.site.finish_at != localStorage.getItem("currentEndDate"))) {
      this.isSelected = true
    }
  },
}
</script>

<style lang="scss" scoped>

  .dropdown-button-holder {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  .is-selected {
    z-index: 3;
  }

</style>
