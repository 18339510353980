<template>
  <div class="range-picker">
    <DatePicker
        v-model="value"
        :attributes="attrs"
        color="gray"
        :columns="2"
        :step="1"
        is-range
    />

  </div>
</template>

<script>
import {DatePicker} from 'v-calendar';
import 'v-calendar/dist/style.css';

export default {
  components: {
    DatePicker
  },
  props: {
    modelValue: Object,
    attrs: Array
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}

</script>

